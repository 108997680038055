<template lang="pug">
</template>

<script>
import {mapActions, mapGetters, mapMutations} from 'vuex';

export default {
  name: 'Breadcrumb',
  data: () => ({
    isExpanded: false,
    exCrumbs: [
      {
        title: 'profile.profileSettingsMenu',
        id: 'profileSettings',
        show: true,
        active: true,
        clicked: false,
        isSubMenuOpen: true,
        icon: 'profile.svg',
        submenu: [
          {
            title: 'profile.breadcrumb.editProfileLabel',
            id: 'editProfile',
            show: true,
            active: true,
            clicked: true,
            icon: 'edit.svg'
          },
          {
            title: 'profile.breadcrumb.securityLabel',
            id: 'security',
            show: true,
            active: true,
            clicked: false,
            icon: 'security.svg'
          },
          {
            title: 'profile.breadcrumb.settingsLabel',
            id: 'settings',
            show: true,
            active: true,
            clicked: false,
            icon: 'settings.svg'
          },
        ],
      },
    ],
    crumbs: [
      {
        title: 'profile.profileSettingsMenu',
        id: 'profileSettings',
        show: true,
        active: true,
        clicked: false,
        isSubMenuOpen: true,
        icon: 'profile.svg',
        submenu: [
          {
            title: 'profile.breadcrumb.editProfileLabel',
            id: 'editProfile',
            show: true,
            active: true,
            clicked: true,
            icon: 'edit.svg'
          },
          {
            title: 'profile.breadcrumb.securityLabel',
            id: 'security',
            show: true,
            active: true,
            clicked: false,
            icon: 'security.svg'
          },
          {
            title: 'profile.breadcrumb.settingsLabel',
            id: 'settings',
            show: true,
            active: true,
            clicked: false,
            icon: 'settings.svg'
          }
        ],
      },
      // {
      //   title: 'profile.operationsMenu',
      //   id: 'operations',
      //   show: true,
      //   active: true,
      //   clicked: false
      // },
      {
        title: 'profile.paymentsMenu',
        id: 'payments',
        show: true,
        active: true,
        clicked: false,
        isSubMenuOpen: false,
        icon: 'payments.svg',
        submenu: [
          {
            title: 'profile.breadcrumb.depositLabel',
            id: 'deposit',
            show: true,
            active: true,
            clicked: false,
            icon: 'deposit.svg'
          },
          {
            title: 'profile.breadcrumb.depositNewLabel',
            id: 'depositNew',
            show: true,
            active: true,
            clicked: false,
            icon: 'deposit.svg'
          },
          {
            title: 'profile.breadcrumb.withdrawalLabel',
            id: 'withdrawal',
            show: true,
            active: true,
            clicked: false,
            icon: 'withdrawal.svg'
          },
          {
            title: 'profile.breadcrumb.historyLabel',
            id: 'paymentsHistory',
            show: true,
            active: true,
            clicked: false,
            icon: 'history.svg'
          },
        ],
      }
    ],
    isClickedExit: false,
  }),
  computed: {
    ...mapGetters({
      isAuthUser: 'auth/isAuthUser',
      userProfile: 'user/getProfile',
      brand: 'main/getBrand',
      openSection: 'personal/getOpenSection',
    }),
    crumbArray() {
      if (this.brand.code == 'ex_w') {
        return this.exCrumbs;
      }
      return this.crumbs;
    }
  },
  methods: {
    ...mapActions({
      doLogout: 'auth/doLogout',
      doPay: 'pay/doPay',
    }),
    ...mapMutations({
      SET_OPEN_SECTION: 'personal/SET_OPEN_SECTION',
      SET_OPEN_PAGE: 'main/SET_OPEN_PAGE',
    }),
    isSelected(id) {
      return this.openSection === id;
    },
    switchActiveTab(idx) {
      this.crumbArray.forEach((tab, index) => {
        if (index === idx) {
          tab.clicked = true;
          tab.isSubMenuOpen = !tab.isSubMenuOpen;
        } else {
          tab.clicked = false;
        }
      });
    },
    async logoutHandler() {
      this.isClickedExit = true;
      this.doLogout();
      this.SET_OPEN_PAGE('root');
      this.$router.push('/');
      window.scrollTo(0, 0);
    },
    /**
     * @param sub
     * метод для выбора активной субвкладки в меню
     */
    selectSubmenu(sub, innerIdx, idx) {
      this.crumbArray.forEach((tab, index) => {
        if (index === idx) {
          tab.submenu.forEach((innerTab, innerIndex) => {
            if (innerIndex === innerIdx) {
              if (innerTab.id === 'depositNew') {
                this.doPay({type: 'depositNew'});
              } else {
                this.SET_OPEN_SECTION(innerTab.id);
              }
              innerTab.clicked = true;
            } else {
              innerTab.clicked = false;
            }
          });
        } else {
          tab.submenu.forEach((innerTab) => {
            innerTab.clicked = false;
          });
        }
      });
    }
  }
};
</script>

<style lang="scss">
</style>
